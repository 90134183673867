<template>
  <a :href="exhibit.link" target="_blank" rel="noreferrer noopener">
    <div
      class="exhibitCardSpecial"
      :class="
        index % 2 === 0
          ? 'exhibitCardSpecial--left'
          : 'exhibitCardSpecial--right'
      "
    >
      <div
        class="exhibitCardSpecial__itemBg clickable"
        :class="
          index % 2 === 0
            ? 'exhibitCardSpecial__itemBg--left'
            : 'exhibitCardSpecial__itemBg--right'
        "
      ></div>

      <a
        :title="`${exhibit.title}(另開新視窗)`"
        class="exhibitCardSpecial__item"
        :style="`background-image: url(${exhibit.image});`"
      >
        <h3 class="exhibitCardSpecial__mainTitle" :id="exhibit.id">
          <span class="exhibitCardSpecial__mainTitleIcon"></span>
          {{ exhibit.title }}
        </h3>
      </a>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    },
    exhibit: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        text: '',
        image: '',
        id: 'gold'
      })
    }
  }
}
</script>

<style lang="scss">
.exhibitCardSpecial {
  width: 90vw;
  padding-bottom: 33.92%;

  position: relative;
  margin: 0 auto;
  margin-bottom: 10rem;

  &__itemBg {
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 33%;
    position: absolute;
    width: 90%;
    background-position: 20% 0;
    bottom: 0;
  }

  &__itemBg--left {
    background-image: url('~@/assets/image/Exhibit/ExhibitCardBackgroundLeft.png');
    transform: translateX(-5.4%);
  }

  &__itemBg--right {
    background-image: url('~@/assets/image/Exhibit/ExhibitCardBackgroundRight.png');
    transform: translateX(6%);
  }

  &__item {
    height: 87%;
    width: 71.5%;
    position: absolute;
    left: 0;
    transform: translateX(17.25%);
    top: 1%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__mainTitleIcon {
    background-image: url('~@/assets/image/TitleIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 3.8rem;
    padding-bottom: 67.8%;
    display: inline-block;
    margin-right: 1.8rem;
    flex-shrink: 0;
  }

  &__mainTitle {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    display: flex;
    align-items: center;
    color: #595757;
    font-weight: 500;
    line-height: 124%;
    letter-spacing: 0.18em;
    font-size: 2.9rem;
    height: 11.2375rem;
  }

  &__descContainer {
    transition: all 0.3s ease-in-out;
    padding: 3rem 5.8rem 0 2rem;
    color: white;
    width: 85%;
    visibility: hidden;
    opacity: 0;
  }
}

@media (max-width: 1090px) {
  .exhibitCardSpecial {
    &__descContainer {
      padding: 1rem 2rem 0 1rem;
      color: white;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .exhibitCardSpecial {
    &__itemBg {
      width: 125%;
      display: none;
    }

    &__item {
      width: 90%;
      height: 110%;
      transform: translateX(6.1%);
    }

    &__mainTitle {
      transform: translateY(-110%);
      align-items: flex-start;
    }

    &__mainTitleIcon {
      padding-bottom: 14.8%;
    }
  }
}
</style>
