<template>
  <section class="exhibit__SpecialCardContainer">
    <ExhibitCardSpecial
      v-for="(exhibit, index) in exhibitLists"
      :key="index"
      :index="index"
      :exhibit="exhibit"
      :onlyImage="true"
    />
  </section>
</template>

<script>
import ExhibitCardSpecial from '@/components/Exhibit/ExhibitCardSpecial.vue'

export default {
  components: { ExhibitCardSpecial },
  data() {
    return {
      // exhibitLists: [
      //   {
      //     mainTitle: '枝枒現象-臺灣當代金工與手勢創作譜記',
      //     title: '',
      //     text: '',
      //     image: require('@/assets/image/Exhibit/ExhibitCardImage-3.png'),
      //     url: 'https://staticweb.omniguider.com/BP/',
      //     id: 'gold'
      //   }
      // ]
    }
  },
  computed: {
    exhibitLists() {
      const exhibit = this.$store.state.exhibit
      if (!exhibit) return []
      const lists = exhibit.find(_exhibit => _exhibit.title === '特展').exhibit

      return lists
    }
  }
}
</script>

<style lang="scss">
.exhibit__SpecialCardContainer {
  padding: 20rem 0 0 0;
  overflow: hidden;
  background-image: url('~@/assets/image/Exhibit/ExhibitGold.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 1.35rem) 8.5rem;
}

@media (max-width: 768px) {
  .exhibit__SpecialCardContainer {
    background-image: none;
  }
}
</style>
